const examples = [
    // "An investor wants to allocate their capital across a range of financial assets to maximize expected returns while minimizing risk. Each asset has an expected return and a risk profile. Formulate a linear program to optimize the asset allocation.",

    // "A call center needs to schedule shifts for its employees. Each shift requires a minimum number of employees, and each employee can work only certain hours. The goal is to minimize the total number of employee-hours while covering all shifts.",

    // "A retailer must decide how much stock to order for each product from various suppliers. Each supplier has different delivery times and costs. The objective is to minimize total supply costs while ensuring stock availability.",

    // "A farmer has a limited amount of land to plant various crops. Each crop has a different profit margin, growth time, and requires a different amount of resources (like water, fertilizer). The goal is to maximize profit from the crops within the resource constraints.",

    // "A tech startup with limited resources must allocate its budget, personnel, and time effectively across three key departments: Research & Development, Marketing, and Sales. The goal is to maximize overall productivity, measured by a combination of innovation metrics, market reach, and sales figures, while staying within budget and manpower limits.",

    // "An energy company needs to optimize the distribution of electricity from different sources (like solar, wind, fossil fuels) to various neighborhoods. Each source has a different production cost and capacity. The objective is to minimize the total cost of energy production while meeting the demand.",

    // "A factory produces several products. Each product requires different amounts of resources like raw materials, machine time, and labor. The factory needs to determine the optimal production schedule to maximize profits while not exceeding resource capacities.",

    // "A dietician wants to create a meal plan using different food items. Each item has a cost and provides certain amounts of nutrients (like protein, carbs, fats). The goal is to minimize the total cost of the diet while meeting the nutritional requirements.",

    // "A logistics company has a fleet of trucks of different capacities. Each truck consumes different amounts of fuel and has varying operational costs. The company wants to maximize its revenue by determining the optimal number of trips and routes for each truck, considering delivery deadlines and customer demands.",

    // "A manufacturing plant produces multiple products, generating different types of waste. The company aims to minimize the total waste produced while meeting production targets and adhering to environmental regulations.",

    // "A hospital needs to schedule doctors and nurses across various departments. Each department requires a minimum number of staff members with specific qualifications. The goal is to minimize the total number of staff while ensuring each department is adequately staffed.",

    // "A travel agency needs to plan tours to various destinations. Each tour has a cost, expected profit, and a limit on the number of tourists. The agency wants to maximize its profits by selecting the optimal set of tours to offer.",

    "An office supply company makes two types of printers: color printers and black and white printers. Different sections of the factory with different teams produce each printer. The color printer team can produce at most 20 color printers per day while the black and white printer team can produce at most 30 black and white printers per day. Both teams require use of the same paper tray installing machine and this machine can make at most 35 printers of either type each day. Color printers generate a profit of $200 per printer while black and white printers generate a profit of $70 per printer. How many of each printer should be made to maximize the company's profit?",

    "A cleaning company uses a cleansing chemical and odor-removing chemical to clean a house. Each unit of the cleansing chemical takes 4 units to be effective while each unit of the odor-removing chemical takes 6 minutes to be effective. The company must use at least 100 units of the cleansing chemical. In total, at least 300 units of chemicals can be used per house. Further, because the cleansing chemical is strong, there can be at most twice the amount of cleansing chemical as odor-removing chemical. How many units of each should be used to minimize the total time it takes for a house to be cleaned?",

    "A drug company is making allergy pills and fever reducing pills in two factories, factory 1 and factory 2. Factory 1 produces 20 allergy pills and 15 fever reducing pills per hour. Factory 2 produces 10 allergy pills and 30 fever reducing pills per hour.  Factory 1 is much more efficient and only requires 20 units of a rare compound while factory 2 requires 30 units of a rare compound. The company only has available 1000 units of the rare compound. If the company must make at least 700 allergy pills and 600 fever reducing pills, how many hours should each factory be run to minimize the total time needed?",

    "A patient can be hooked up to two machines to have medicine delivered, machine 1 and machine 2. Machine 1 delivers 0.5 units of medicine to the heart per minute and 0.8 units of medicine per minute to the brain. Machine 2 delivers 0.3 units of medicine per minute to the heart and 1 unit of medicine per minute to the brain. In addition however, machine 1 creates 0.3 units of waste per minute while machine 2 creates 0.5 units of waste per minute. If at most 8 units of medicine can be received by the heart and at least 4 units of medicine should be received by the brain, how many minutes should each machine be used to minimize the total amount of waste produced?",

    "A company make both liquid and foam hand sanitizer. Liquid hand sanitizer requires 40 units of water and 50 units of alcohol. Foam hand sanitizer requires 60 units of water and 40 units of alcohol. The company has available 2000 units of water and 2100 units of alcohol. The number of foam hand sanitizers made must exceed the number of liquid hand sanitizers. In addition, at most 30 liquid hand sanitizers can be made. If each liquid hand sanitizer can clean 30 hands and each foam hand sanitizer can clean 20 hands, how many of each should the company make to maximize the number of hands that can be cleaned?",

    "A university research lab can make two types of diabetes medicine, medicine A and medicine B. Per dose, medicine A takes 30 units of imported material and 50 units of mRNA to make. Per dose, medicine B takes 40 units of imported material and 30 units of mRNA to take. The lab has available at most 300 units of imported material and 400 units of mRNA. The lab can make at most 5 doses of medicine A and the number of doses of medicine B must be larger than the number of dosed of medicine A. If one dose of medicine A can treat 12 people and one dose of medicine B can treat 8 people, how many doses of each should be made to maximize the number of people that can be treated?",

    "Children can go to school either by van or by minibus. A van can take 6 kids and produces 7 units of pollution. A minibus can take 10 kids and produced 10 units of pollution. There are at least 150 kids than need to go to school and at most 10 minibuses can be used. In addition, the number of vans used must exceed the number of minibuses. How many of each should be used to minimize the total amount of pollution produced?",

    "An artisan makes two types of terracotta jars: a thin jar and a stubby jar. Each thin jar requires 50 minutes of shaping time and 90 minutes of baking time. Each stubby jar requires 30 minutes of shaping time and 150 minutes of baking time. Per week, there are 3000 minutes available for shaping and 4000 minutes available for baking. The profit per thin jar is $5 and the profit per stubby jar is $9. How many jars of each type should the artisan make to maximize profit?",

    "A car manufacturing company makes hamburgers and chicken wraps for workers. They need to ensure workers get at least 2200 calories, 50 grams of protein, and 70 grams of carbs. One hamburger costs $6.5 and contains 800 calories, 19 grams of protein, and 20 grams of carbs. One chicken wrap costs $4 and contains 450 calories, 12 grams of protein, and 10 grams of carbs. What is the minimum cost diet that the company can provide for its workers?",

    "Maple Oil processes three types of crude oil: light oil, non-sticky oil and heavy oil. Each tank of light oil produces a net revenue of $550, each tank of non-sticky oil produces a net revenue of $750, and each tank of heavy oil produces a net revenue of $950. To process a tank of light oil, 3 units of compound A and 3 units of compound B are required. To process a tank of non-sticky oil, 6 units of compound A and 2 units of compound B are required. To process a tank of heavy oil, 9 units of compound A and 3 units of compound B are required. Currently the company has 250 units of compound A and 150 units of compound B to process. How many full or partial tanks of each oil should the company process so that net revenue is maximized?",

    "A bakery uses a stand-mixer and a slow bake oven to make bread and cookies. Each machine can run for at most 3000 hours per year. To bake a loaf of bread takes 1 hour in the stand mixer and 3 hours in the oven. A batch of cookies requires 0.5 hours in the mixer and 1 hour in the oven. The profit per loaf of bread is $5 and the profit per batch of cookies is $3. How should the bakery operate to maximize total profit?",

    "A restaurant has two combos available to their customers, an original and an experimental meal. The original meal typically generates 20 units of food waste and 45 units of wrapping waste and takes 10 minutes to cook. In comparison, the experimental meal generates 25 units of food waste and 35 units of wrapping waste and takes 15 minutes to cook. The restaurant can have at most 900 units of wrapping waste and can have at most 800 units of food waste as regulated by the government. How many of each combo should be pushed for by the waiting staff to minimize the cooking time?",
];

export default examples;
